@import "../../../../Assets/styles/variables";

.oneproject {
  .top {
    display: flex;
    flex-direction: column;
    padding: 40px 5rem 4rem;

    .title {
      padding: 30px 0 40px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
    }

    .details {
      display: flex;

      .left {
        flex: 1 1 0px;

        img {
          border-radius: 10px;
          width: 95%;
          height: 80%;
        }
      }

      .right {
        flex: 1 1 0px;
        padding: 10px;

        .location {
          font-size: 17px;

          img {
            width: 10px;
            height: 14px;
            margin-right: 8px;
          }
        }

        .body {
          padding-top: 20px;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          text-align: justify;
          line-height: 1.5;
        }

        .under {
          display: flex;
          padding: 40px 0 25px;
          border-bottom: 1px solid #cfe0f8;

          .price{
            margin: 0 3rem 0 2rem;
            position: relative;

            img {
              position: absolute;
              right: 60px;
              top: -10px;
              height: 35px;
            }
          }
          .datedue {
            margin: 0 3rem;
            position: relative;

            img {
              position: absolute;
              right: 120px;
              top: -10px;
              height: 35px;
            }
          }
        }

        .tag {
          display: flex;
          flex-flow: row wrap;
          padding: 20px 0;
          width: 60%;

          p {
            background: #e2f6ff;
            color: #0251bc;
            padding: 7px 10px;
            margin: 10px 10px 7px 0;
            font-size: 12px;
          }
        }

        .action {
          display: flex;
          justify-content: flex-end;
          padding-top: 10px;
        }
      }
    }
  }

  .middle {
    display: flex;
    flex-direction: column;
    padding: 20px 5rem 4rem;
    background: #f8fdff;

    h3 {
      font-family: "Roboto", sans-serif;
      font-size: 25px;
      color: #0251bc;
      font-weight: 400;
      padding: 20px 50px 40px;
      margin: 0 auto;
    }

    .otherprojects {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 40px;
    }

    .dots {
      height: 12px;
      width: 5%;
      margin: 0 auto;
    }
  }
}