@import "../../Assets/styles/variables";

.button {
  background-color: $wkblue;
  color: #fff;
  border-radius: 5px;
  width: auto;
  padding: 20px 50px;
  border: none;
  font-size: 16px;
  line-height: 139%;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  img {
    width: 5px;
    margin-left: 15px;
    margin-top: 3px;
  }
  .attach {
    height: 18px;
    width: 15px;
    margin-right: 5px;
  }
  &.full {
    width: 100%;
  }

  &.red {
    background-color: $wkred;
  }
  &.green {
    background-color: $wkgreen;
  }
  &.invert {
    color: $wkblue;
    background: none;
    border: 1px solid $wkblue;

    &.red {
      color: $wkred;
      border-color: $wkred;
    }
  }
  &.medium {
    font-size: 20px;
    padding: 20px 50px;
    img {
      margin-top: 5px;
    }
  }
  &.big {
    font-size: 24px;
    padding: 21px 70px;
    img {
      margin-top: 5px;
    }
  }

  &.small {
    font-size: 12px;
    padding: 13px;
    // width: 13%;
  }
  &.disabled {
    cursor: no-drop;
    opacity: 0.5;
  }

  &:hover {
    // transform: scale(1.05);
  }
}
