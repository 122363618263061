@import "../../../../Assets/styles/variables";

.mainSection {
  .top {
    padding: 25px;
    width: 100%;
    background: white;

    p {
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  .cardContainer {
    padding: 25px;
    width: 100%;
    background: white;
    margin: 20px 0;

    p {
      margin-bottom: 20px;
      font-size: 14px;
    }

    .freelancers {
      display: grid;
      grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
      gap: 30px 2%;
      padding-top: 20px;
    }

    .paginate {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      padding: 40px 0 0;

      .numbers {
        display: flex;
        flex-direction: row;

        p {
          margin-right: 10px;
          background: #cae8f5;
          padding: 5px 8px;
          font-size: 12px;
          color: #0251bc;
          font-weight: bold;

          &:first-of-type {
            background: #0251bc;
            color: white;
          }
        }
      }

      .navarrow {
        display: flex;
        flex-direction: row;

        .arrow {
          margin-right: 10px;
          background: #0251bc;
          padding: 5px 10px;
          width: 25px;
          height: 26px;
          position: relative;

          img {
            position: absolute;
            height: 9px;
            top: 7.5px;
          }
        }
      }
    }
  }
}